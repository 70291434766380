import React from "react"
import { Link } from "gatsby"
import * as BS from "react-bootstrap"

import { FaAngleDoubleRight} from 'react-icons/fa'
import styled from 'styled-components'


const Divider = styled.span`
margin: 0 8px;
padding-right: 1px;
`

const HeaderWrapper = styled.header`
  background: #fff;
  margin-bottom: 1.45rem;
  border-bottom: 0px solid rgb(227, 235, 246);
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  display: flex;
  margin: 0 auto;
  padding: 10px 0px 10px 0px;
  z-index: 10;
  max-width: 1400px;

  @media only screen and (max-width: 768px) {
    border-bottom: 0px solid rgb(227, 235, 246);
    padding: 10px 20px 10px 20px;
  }
`

const LogoWrapper = styled.div`
  flex-grow: 1;
`
const LoginWrapper = styled.div`
  margin: 'auto 0';
`
const Signup = styled.span`
@media only screen and (max-width: 768px) {
      
    }
`

const Login = styled.span`
@media only screen and (max-width: 768px) {
      margin-right: -20px;
      display:none;
    }
`

function Header() {

  // const whichHeader = () => {

  //   return (
  //   <>
  //   <Login><Link to='/login/'><button type="button" className="btn btn-outline-dark">Login</button></Link></Login>
  //   <Divider />
  //   <Signup id='signUpMobile'> <Link to='/signup/'><button type="button" className="btn btn-primary">The Latest Unicorn In Your Inbox <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginLeft: 5}}/></button></Link></Signup>
  //   <Signup id='signUpDesktop'> <Link to='/signup/'><button type="button" className="btn btn-primary">Sign Up</button></Link></Signup>
  //   </>

  //   )
  // }

  return (

  

<BS.Container>
<HeaderWrapper>
  <LogoWrapper id='logoWrap'>
    <Link to="/">
      <img src="/unicornalert-logo.png" alt="Unicorn Alert" style={{ height: 50, marginBottom: 0, marginLeft: -8}}></img>
    </Link>
  </LogoWrapper>

  <LoginWrapper id='loginWrap'>
  <Login><Link to='/login/'><button type="button" className="btn btn-outline-dark">Login</button></Link></Login>
    <Divider />
    <Signup id='signUpMobile'> <Link to='/signup/'><button type="button" className="btn btn-primary">The Latest Unicorn In Your Inbox <FaAngleDoubleRight style={{marginTop: 5, marginLeft: 5}}/></button></Link></Signup>
    <Signup id='signUpDesktop'> <Link to='/signup/'><button type="button" className="btn btn-primary">Sign Up</button></Link></Signup>
    </LoginWrapper>
    </HeaderWrapper>
    </BS.Container>
  )
}

export default Header
