// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-angel-investors-js": () => import("./../../../src/pages/angel-investors.js" /* webpackChunkName: "component---src-pages-angel-investors-js" */),
  "component---src-pages-business-js": () => import("./../../../src/pages/business.js" /* webpackChunkName: "component---src-pages-business-js" */),
  "component---src-pages-index-2-js": () => import("./../../../src/pages/index2.js" /* webpackChunkName: "component---src-pages-index-2-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investment-js": () => import("./../../../src/pages/investment.js" /* webpackChunkName: "component---src-pages-investment-js" */),
  "component---src-pages-investor-js": () => import("./../../../src/pages/investor.js" /* webpackChunkName: "component---src-pages-investor-js" */),
  "component---src-pages-investor-type-angel-js": () => import("./../../../src/pages/investor-type/angel.js" /* webpackChunkName: "component---src-pages-investor-type-angel-js" */),
  "component---src-pages-investor-type-angel-network-js": () => import("./../../../src/pages/investor-type/angel-network.js" /* webpackChunkName: "component---src-pages-investor-type-angel-network-js" */),
  "component---src-pages-investor-type-corporate-vc-js": () => import("./../../../src/pages/investor-type/corporate-vc.js" /* webpackChunkName: "component---src-pages-investor-type-corporate-vc-js" */),
  "component---src-pages-investor-type-family-office-js": () => import("./../../../src/pages/investor-type/family-office.js" /* webpackChunkName: "component---src-pages-investor-type-family-office-js" */),
  "component---src-pages-investor-type-vc-js": () => import("./../../../src/pages/investor-type/vc.js" /* webpackChunkName: "component---src-pages-investor-type-vc-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-market-ai-js": () => import("./../../../src/pages/market/ai.js" /* webpackChunkName: "component---src-pages-market-ai-js" */),
  "component---src-pages-market-biotech-js": () => import("./../../../src/pages/market/biotech.js" /* webpackChunkName: "component---src-pages-market-biotech-js" */),
  "component---src-pages-market-business-software-js": () => import("./../../../src/pages/market/business-software.js" /* webpackChunkName: "component---src-pages-market-business-software-js" */),
  "component---src-pages-market-cannabis-js": () => import("./../../../src/pages/market/cannabis.js" /* webpackChunkName: "component---src-pages-market-cannabis-js" */),
  "component---src-pages-market-cars-js": () => import("./../../../src/pages/market/cars.js" /* webpackChunkName: "component---src-pages-market-cars-js" */),
  "component---src-pages-market-construction-js": () => import("./../../../src/pages/market/construction.js" /* webpackChunkName: "component---src-pages-market-construction-js" */),
  "component---src-pages-market-cyber-security-js": () => import("./../../../src/pages/market/cyber-security.js" /* webpackChunkName: "component---src-pages-market-cyber-security-js" */),
  "component---src-pages-market-data-js": () => import("./../../../src/pages/market/data.js" /* webpackChunkName: "component---src-pages-market-data-js" */),
  "component---src-pages-market-drone-js": () => import("./../../../src/pages/market/drone.js" /* webpackChunkName: "component---src-pages-market-drone-js" */),
  "component---src-pages-market-ecommerce-js": () => import("./../../../src/pages/market/ecommerce.js" /* webpackChunkName: "component---src-pages-market-ecommerce-js" */),
  "component---src-pages-market-edtech-js": () => import("./../../../src/pages/market/edtech.js" /* webpackChunkName: "component---src-pages-market-edtech-js" */),
  "component---src-pages-market-energy-js": () => import("./../../../src/pages/market/energy.js" /* webpackChunkName: "component---src-pages-market-energy-js" */),
  "component---src-pages-market-fintech-js": () => import("./../../../src/pages/market/fintech.js" /* webpackChunkName: "component---src-pages-market-fintech-js" */),
  "component---src-pages-market-foodtech-js": () => import("./../../../src/pages/market/foodtech.js" /* webpackChunkName: "component---src-pages-market-foodtech-js" */),
  "component---src-pages-market-freighttech-js": () => import("./../../../src/pages/market/freighttech.js" /* webpackChunkName: "component---src-pages-market-freighttech-js" */),
  "component---src-pages-market-gaming-js": () => import("./../../../src/pages/market/gaming.js" /* webpackChunkName: "component---src-pages-market-gaming-js" */),
  "component---src-pages-market-hardware-js": () => import("./../../../src/pages/market/hardware.js" /* webpackChunkName: "component---src-pages-market-hardware-js" */),
  "component---src-pages-market-healthtech-js": () => import("./../../../src/pages/market/healthtech.js" /* webpackChunkName: "component---src-pages-market-healthtech-js" */),
  "component---src-pages-market-hrtech-js": () => import("./../../../src/pages/market/hrtech.js" /* webpackChunkName: "component---src-pages-market-hrtech-js" */),
  "component---src-pages-market-insurtech-js": () => import("./../../../src/pages/market/insurtech.js" /* webpackChunkName: "component---src-pages-market-insurtech-js" */),
  "component---src-pages-market-js": () => import("./../../../src/pages/market.js" /* webpackChunkName: "component---src-pages-market-js" */),
  "component---src-pages-market-martech-js": () => import("./../../../src/pages/market/martech.js" /* webpackChunkName: "component---src-pages-market-martech-js" */),
  "component---src-pages-market-proptech-js": () => import("./../../../src/pages/market/proptech.js" /* webpackChunkName: "component---src-pages-market-proptech-js" */),
  "component---src-pages-market-vr-js": () => import("./../../../src/pages/market/vr.js" /* webpackChunkName: "component---src-pages-market-vr-js" */),
  "component---src-pages-pre-seed-vcs-js": () => import("./../../../src/pages/pre-seed-vcs.js" /* webpackChunkName: "component---src-pages-pre-seed-vcs-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-round-pre-seed-js": () => import("./../../../src/pages/round/pre-seed.js" /* webpackChunkName: "component---src-pages-round-pre-seed-js" */),
  "component---src-pages-round-seed-js": () => import("./../../../src/pages/round/seed.js" /* webpackChunkName: "component---src-pages-round-seed-js" */),
  "component---src-pages-round-series-a-js": () => import("./../../../src/pages/round/series-a.js" /* webpackChunkName: "component---src-pages-round-series-a-js" */),
  "component---src-pages-round-series-b-js": () => import("./../../../src/pages/round/series-b.js" /* webpackChunkName: "component---src-pages-round-series-b-js" */),
  "component---src-pages-seed-vcs-js": () => import("./../../../src/pages/seed-vcs.js" /* webpackChunkName: "component---src-pages-seed-vcs-js" */),
  "component---src-pages-series-a-vcs-js": () => import("./../../../src/pages/series-a-vcs.js" /* webpackChunkName: "component---src-pages-series-a-vcs-js" */),
  "component---src-pages-series-b-vcs-js": () => import("./../../../src/pages/series-b-vcs.js" /* webpackChunkName: "component---src-pages-series-b-vcs-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-business-template-js": () => import("./../../../src/templates/businessTemplate.js" /* webpackChunkName: "component---src-templates-business-template-js" */),
  "component---src-templates-investor-template-js": () => import("./../../../src/templates/investorTemplate.js" /* webpackChunkName: "component---src-templates-investor-template-js" */),
  "component---src-templates-location-template-js": () => import("./../../../src/templates/locationTemplate.js" /* webpackChunkName: "component---src-templates-location-template-js" */)
}

