import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import * as BS from "react-bootstrap"
import { globalHistory as history} from '@reach/router'
import {FaTwitter} from 'react-icons/fa'

const Footer = ({ siteTitle }) => {

const isNotSignUp = () => {
  const { location } = history

  if(location.pathname !== '/signup/' && location.pathname !== '/thank-you/' && location.pathname !== '/signup-newsletter/'){
    return (
<footer className="pt-4 my-md-5 pt-md-5 border-top" >
        <BS.Container style={{maxWidth: 1400}}>
        <BS.Row style={{marginLeft: 50, marginRight: 50}}>
         
          <BS.Col>
            <h5>Features</h5>
            <ul className="text-small" style={{fontSize: 14, marginBottom: 0}}>
              {/* <li><Link to={'/dashboard/'} className="text-muted">Dashboard</Link></li> */}
              <li><Link to={'/investment/'} className="text-muted">Investments</Link></li>
              <li><Link to={'/business/'} className="text-muted">Businesses</Link></li>
              <li><Link to={'/investor/'} className="text-muted">Investors</Link></li>
              <li><Link to={'/market/'} className="text-muted">Markets</Link></li>
            </ul>
          </BS.Col>
          <BS.Col id='f1'>
            <h5>Markets</h5>
            <ul className="text-small" style={{fontSize: 14}}>
              <li><Link to={'/market/ai/'} className="text-muted" >AI</Link></li>
              <li><Link to={'/market/biotech/'} className="text-muted" >Biotech</Link></li>
              <li><Link to={'/market/business-software/'} className="text-muted" >Business Software</Link></li>
              <li><Link to={'/market/cars/'} className="text-muted" >Cars</Link></li>
              <li><Link to={'/market/cyber-security/'} className="text-muted" >Cyber Security</Link></li>
              <li><Link to={'/market/data/'} className="text-muted" >Data</Link></li>
            </ul>
          </BS.Col>
          <BS.Col id='f1'>
            
            <ul className="text-small" style={{fontSize: 14, marginTop: 36}}>
            <li><Link to={'/market/edtech/'} className="text-muted" >Edtech</Link></li>
              <li><Link to={'/market/fintech/'} className="text-muted" >Fintech</Link></li>
              <li><Link to={'/market/healthtech/'} className="text-muted" >Healthtech</Link></li>
              <li><Link to={'/market/hrtech/'} className="text-muted" >HRTech</Link></li>
              <li><Link to={'/market/proptech/'} className="text-muted">Proptech</Link></li>
            </ul>
          </BS.Col>

          <BS.Col id='f2'>
            <h5>Locations</h5>
            <ul className="text-small" style={{fontSize: 14}}>
              <li><Link to={'/usa/san-francisco/'} className="text-muted">San Francisco, USA</Link></li>
              <li><Link to={'/usa/nyc/'} className="text-muted">New York, USA</Link></li>
              <li><Link to={'/uk/london/'} className="text-muted">London, UK</Link></li>
              <li><Link to={'/china/shanghai/'} className="text-muted">Shanghai, China</Link></li>
              <li><Link to={'/usa/boston/'} className="text-muted">Boston, USA</Link></li>
              <li><Link to={'/usa/mountain-view/'} className="text-muted">Mountain View, USA</Link></li>
            </ul>
            </BS.Col>
            <BS.Col>
            <h5>About</h5>
            <ul className="text-small" style={{fontSize: 14}}>
            <li><Link to={'/signup/'} className="text-muted">Sign Up</Link></li>
            <li><Link to={'/login/'} className="text-muted">Login</Link></li>
            <li><Link to={'/pricing/'} className="text-muted">Pricing</Link></li>
              <li><Link to={'/terms-conditions/'} className="text-muted" href="/terms-conditions/">Terms & Conditions</Link></li>
              <li><Link to={'/privacy-policy/'}className="text-muted" href="/privacy-policy/">Privacy Policy</Link></li>
            </ul>
          </BS.Col>
        </BS.Row>
        </BS.Container>

        <div style={{ margin: 25, textAlign: 'center' }}>© 2020 <Link to="/">
          <b style={{ color: 'black' }}>unicornalert</b></Link>
           - <a href='https://twitter.com/unicornalertio' rel="noreferrer" target='_blank'>
             <FaTwitter /></a> 
    </div>

      </footer>
    )
} 
}

return(
<span>
  {isNotSignUp()}
  </span>
)
 
     


}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
